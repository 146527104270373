$(document).ready(function(){
  var win = $(window);
  if($('.banner-wrapper .owl-carousel').length){
    changeImageBanner()
    $('.banner-wrapper .owl-carousel').owlCarousel({
      items: 1,
      nav: true,
      loop: true,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
      navText: ["<span class='nav-wrap'><i class='icon icon-arrow-5-left'></i></span>","<span class='nav-wrap'><i class='icon icon-arrow-5-right'></i></span>"],
      autoHeight:true,
      autoplay:false,
      autoplayTimeout: 7000,
    })
  }
  if ($('.unwind-detail-container .attraction-gallery').length) {
      changeImageBannerDetail();
  }
  if ($(".banner-container-cover").length) {
      changeImageBannerPageDetail();
  }
  if ($(".attraction-gallery").length) {
      changeImageBannerDetail();
  }
  function changeImageBannerDetail() {
      var itemBannerDetail = $(".slick-slider-content-image");
      if (itemBannerDetail.length) {
          $.each(itemBannerDetail, function () {
              var _this = $(this);
              var data = _this.data();
              if (win.width() >= 1024) {
                  _this.css("background-image", "url('" + data.imageMd + "')");
              } else if (win.width() >= 768) {
                  _this.css("background-image", "url('" + data.imageSm + "')");
              } else {
                  _this.css("background-image", "url('" + data.imageXs + "')");
              }
          })
      }
  }
  function changeImageBannerPageDetail() {
      var itemBannerDetail = $(".banner-container-cover");
      if (itemBannerDetail.length) {
          var _this = $(".banner-container-cover");
        var data = _this.data();
        if (win.width() >= 1024) {
            _this.css("background-image", "url('" + data.imageMd + "')");
        } else if (win.width() >= 768) {
            if (!isEmpty(data.imageSm)) {
                _this.css("background-image", "url('" + data.imageSm + "')");
            } else {
                _this.css("background-image", "url('" + data.imageMd + "')");
            }
        } else {
            if (!isEmpty(data.imageXs)) {
                _this.css("background-image", "url('" + data.imageXs + "')");
            } else {
                _this.css("background-image", "url('" + data.imageMd + "')");
            }
        }
      }
  }
  function isEmpty(text) {
      if (text === null || text === undefined || text === '') {
          return true;
      } else
          return false;
  }
  function changeImageBanner(){
    var itemBanner = $(".item-banner");
    if(itemBanner.length){
      $.each(itemBanner, function(){
        var _this = $(this);
        var data = _this.data();
        if(win.width() >= 1024){
          _this.css("background-image","url('"+data.imageMd+"')");
        }else if(win.width() >= 768){
          _this.css("background-image","url('"+data.imageSm+"')");
        }else{
          _this.css("background-image","url('"+data.imageXs+"')");
        }
      })
    }
  }

  win.on("resize", function(){
      changeImageBanner();
      changeImageBannerDetail();
      changeImageBannerPageDetail();
  })
});