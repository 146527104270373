$(document).ready(function(){
  var map;
  var markerAirport;
  var markerSearch;
  var markers = [];
  var directionsDisplay;
  var ggmap = $(".gg-map-wrapper");

  try{
    var infowindow = new google.maps.InfoWindow({size: new google.maps.Size(150,50)});

    var iconMarker = {
      url: "https://dl.dropboxusercontent.com/s/ox8t6m0t5rxntyk/marker.png?dl=0",
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(35, 35)
    };
    var iconMarkerEnd = {
      url: "https://dl.dropboxusercontent.com/s/5ogs3zxlfdw6wep/marker.png?dl=0",
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(35, 35)
    };
  }catch(e){
    console.log(e.message)
  }
  if(ggmap.length){
    $.each(ggmap, function(){
      var _this = $(this);
      var data = _this.data();
      initMap(data.lat,data.lng,data.searchBox);
      if(data.searchBox == true){
        displaySearchBox(data);
      }
    })
  }
  function displaySearchBox(dataMap) {
    // Create the search box and link it to the UI element.
    var input = document.getElementById('pac-input');
    var searchBox = new google.maps.places.SearchBox(input);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    // Bias the SearchBox results towards current map's viewport.
    map.addListener('bounds_changed', function() {
      searchBox.setBounds(map.getBounds());
    });
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', function() {
      var places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      if(markers && markers.length){
        $.each(markers, function(key,marker){
          marker.setMap(null)
        });
        markers = [];
      }

      // For each place, get the icon, name and location.
      var bounds = new google.maps.LatLngBounds();
      places.forEach(function(place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
        displayRoute(new google.maps.LatLng(dataMap.lat,dataMap.lng),new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()))
      });
      map.fitBounds(bounds);
    });
  }
  function displayRoute(start,end) {
    if(!start){
      start = new google.maps.LatLng(28.694004, 77.110291);
    }
    if(!end){
      end = new google.maps.LatLng(28.72082, 77.107241);
    }

    if(directionsDisplay){
      directionsDisplay.setMap(null);
      directionsDisplay = null;
    }

    directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: true});// also, constructor can get "DirectionsRendererOptions" object
    directionsDisplay.setMap(map); // map should be already initialized.

    var request = {
        origin : start,
        destination : end,
        travelMode : google.maps.TravelMode.DRIVING
    };
    var directionsService = new google.maps.DirectionsService(); 
    directionsService.route(request, function(response, status) {
      if (status == google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
        var leg = response.routes[ 0 ].legs[ 0 ];
        makeMarker( leg.start_location, iconMarker, "Start", leg.start_address);
        makeMarker( leg.end_location, iconMarkerEnd, 'End', leg.end_address);
      }
    });
  }
  function makeMarker( position, icon, title, desc) {
    var marker = new google.maps.Marker({
      position: position,
      map: map,
      icon: icon,
      title: title
    });
    google.maps.event.addListener(marker, 'click', function() {
      infowindow.setContent(desc); 
      infowindow.open(map,marker);
    });
    markers.push(marker);
  }
  function initMap(lat,lng,searchBox) {
    if(!lat || !lng){
      return false;
    }
    try{      
      // Basic options for a simple Google Map
      // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
      var mapOptions = {
        // How zoomed in you want the map to start at (always required)
        zoom: 14,

        // The latitude and longitude to center the map (always required)
        center: new google.maps.LatLng(lat, lng), // New York

        // How you would like to style the map. 
        // This is where you would paste any style found on Snazzy Maps.
        styles: [{"featureType":"landscape.man_made","elementType":"geometry","stylers":[{"color":"#f7f1df"}]},{"featureType":"landscape.natural","elementType":"geometry","stylers":[{"color":"#d0e3b4"}]},{"featureType":"landscape.natural.terrain","elementType":"geometry","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.medical","elementType":"geometry","stylers":[{"color":"#fbd3da"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#bde6ab"}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"simplified"},{"color":"#aaaaaa"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffe15f"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#efd151"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"color":"black"}]},{"featureType":"transit.station.airport","elementType":"geometry.fill","stylers":[{"color":"#cfb2db"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#a2daf2"}]}]
      };

      // Get the HTML DOM element that will contain your map 
      // We are using a div with id="map" seen below in the <body>
      var mapElement = document.getElementById('contact-map');

      // Create the Google Map using our element and options defined above
      map = new google.maps.Map(mapElement, mapOptions);
      markerAirport = new google.maps.Marker({
        icon: iconMarker,
        map: map,
        position: new google.maps.LatLng(lat, lng),
      });
      markers.push(markerAirport);
      
    }catch(e){
      console.log(e.message)
    }
  }
});