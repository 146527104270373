$(document).ready(function(){
  var md = new MobileDetect(window.navigator.userAgent);
  var isDevice = false;
  var isMobile = false;
  var isTablet = false;
  var win = $(window);
  var videoContainer = $(".video-container");
  var video = $("#video-about");
  var btnPlay = $(".video-play");
  var btnPause = $(".video-pause");
  if(md.phone()){
    isMobile = true;
  }
  if(md.tablet()){
    isTablet = true;
  }
  if(md.phone() || md.tablet()){
    isDevice = true
  }

  function parallax() {
    var videoHeight = $("#video-about").height();
    var videoContainerHeight = videoContainer.innerHeight();
    var transformValue = (videoHeight - videoContainerHeight)/2;
    var scrollPos = win.scrollTop();

    if ( scrollPos < videoHeight - videoContainerHeight) {
      if(scrollPos > transformValue){
        transformValue = scrollPos
      }
      video.css({
        'top': -transformValue + 'px'
      });
    }
  }

  function videoPlay(play){
    if(play == false){
      video.get(0).pause();
      btnPlay.show();
      btnPause.hide();
    }else{
      video.get(0).play();
      btnPause.show();
      btnPlay.hide();
    }
  }
  if(video.length){
    if(!isDevice){
      videoPlay(true);
    }else{
      videoPlay(false);
    }
    btnPause.on("click",function(){
      videoPlay(false);
    });
    btnPlay.on("click",function(){
      videoPlay(true);
    });
    setTimeout(function(){
      parallax();
    },500)
    win.on("scroll", function(){
      var scrollTop = win.scrollTop();
      if(scrollTop>videoContainer.innerHeight()+videoContainer.offset().top){
        videoPlay(false);
      }
      parallax();
    })
  }
});