function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

var MenuTop;
var TopHeader;
var ScrollTop;

$(document).ready(function(){
  window.fbAsyncInit = function() {
    FB.init({
      appId            : '1406087542770657',
      autoLogAppEvents : true,
      xfbml            : true,
      version          : 'v2.9'
    });
    FB.AppEvents.logPageView();
  };

  var mobile_toggle = $(".toggle-menu-mobile");
  var header_overlay = $(".header-container-overlay");
  var body = $("body");
  var html = $("html");
  var isOpen = false;
  var md = new MobileDetect(window.navigator.userAgent);
  var isDevice = false;
  var isMobile = false;
  var isTablet = false;
  var win = $(window);
  var winWidth = win.width();
  if(md.phone()){
    isMobile = true;
  }
  if(md.tablet()){
    isTablet = true;
  }
  if(md.phone() || md.tablet()){
    isDevice = true
  }

  body.css("overflow","visible");
  $(".preload").hide();

  if($(".button-send-fb").length){
    if(isDevice){
      $(".button-send-fb").hide();
    }else{
      $(".button-send-fb").on("click", function(e){
        var _this = $(this);
        var data = _this.data();
        FB.ui({
          method: 'send',
          link: data.href,
        });
      })
    }
  }
  function toggleMenu(){
    if(header_overlay.hasClass("active")) {
      header_overlay.removeClass("active");
      var css = {
        "overflow":"visible",
        "height":"100%",
      }
      body.css(css);
      html.css(css);
    }else{
      var css = {
        "overflow":"hidden",
        "height":"auto",
      }
      header_overlay.addClass("active");
      body.css(css);
      html.css(css);
    }
  }
  if($(".nano").length){
    $(".nano").nanoScroller({
      preventPageScrolling:true,
      alwaysVisible: true
    });
  }
  if($('.intro-slider .owl-carousel').length){
    $('.intro-slider .owl-carousel').owlCarousel({
      nav: true,
      loop: true,
      autoplay:true,
      autoplayTimeout: 3000,
      navText: ["<span class='nav-wrap'><i class='icon-arrow-left'></i></span>","<span class='nav-wrap'><i class='icon-arrow-right'></i></span>"],
      responsive:{
        0:{
          autoWidth: true,
        },
        425:{
          autoWidth: true,
        },
        768:{
          autoWidth: false,
        },
        769:{
          autoWidth: true,
        },
        1024:{
          autoWidth: false,
        }
      }
    });
  }

  if($('.photo-gallery-carousel-container .owl-carousel').length){
    $('.photo-gallery-carousel-container .owl-carousel').owlCarousel({
      nav: true,
      loop: true,
      // autoplay:true,
      // autoplayTimeout: 3000,
      margin: 36,
      navText: ["<span class='nav-wrap'><i class='icon-arrow-left'></i></span>","<span class='nav-wrap'><i class='icon-arrow-right'></i></span>"],
      items: 3,
      responsive:{
        0:{
          items: 1,
        },
        420:{
          items: 1,
        },
        567:{
          items: 2,
        },
        767:{
          items: 3,
        },
        992:{
          items: 3,
        }
      }
    });
  }
  if($('.carousel-container .owl-carousel').length){
    $('.carousel-container .owl-carousel').owlCarousel({
      nav: true,
      loop: true,
      // autoplay:true,
      // autoplayTimeout: 3000,
      margin: 36,
      navText: ["<span class='nav-wrap'><i class='icon-arrow-left'></i></span>","<span class='nav-wrap'><i class='icon-arrow-right'></i></span>"],
      items: 4,
      responsive:{
        0:{
          items: 1,
        },
        567:{
          items: 2,
        },
        767:{
          items: 3,
        },
        992:{
          items: 4,
        }
      }
    });
  }


  $('.media-extra-slide').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });
  if($(".attraction-gallery").length){
    var attraction_thumb_slider = $('.attraction-thumb-slider');
    attraction_thumb_slider.slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: '.attraction-item-slider',
      arrows: false,
      focusOnSelect: true,
      infinite: true,
    });
    var attraction_item_slider = $('.attraction-item-slider');
    attraction_item_slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      asNavFor: '.attraction-thumb-slider',
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 4000
    });
    attraction_item_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
      infoSlider.slick("slickGoTo", nextSlide)
    });

    var info_slider_container = $(".info-slider-container .info-slider");
    var infoSlider = info_slider_container.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      adaptiveHeight: true
    });
    var current = 0; // current slider dupa refresh
    $('.attraction-thumb-slider .slick-slide:not(.slick-cloned)').eq(current).addClass('slick-current');
    $('.attraction-item-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
      current = currentSlide;
      $('.attraction-thumb-slider .slick-slide').removeClass('slick-current');
      $('.attraction-thumb-slider .slick-slide:not(.slick-cloned)').eq(current).addClass('slick-current');
    });
  }
  if ($(".btn-media-content").length) {
      var video = document.getElementById('video-about');
      $(".btn-media-content").on("click", function () {
          var url = $(this).attr("data-media");
          if ($(video).length)
          {
           $(".video-play").hide();
           $(".video-pause").show();
            video.src = url;
            video.load();
            video.play();
          }
      });
  }
  if($(".banner-form-toggle").length){
    $(".banner-form-toggle").on("click", function(){
      var elm = $(this);
      var elm_next_form = elm.next(".banner-form");
      if(elm.hasClass("active")){
        elm.removeClass("active");
        elm_next_form.removeClass("active");
      }else{
        elm.addClass("active");
        elm_next_form.addClass("active");
      }
    })
  }

  if($(".feature-tab .tab-item").length){
    $(".feature-tab .tab-item").on("click", function(){
      var feature_content = $(".tab-content.feature-content");
      var _this = $(this);
      feature_content.addClass("active");
      if(_this.hasClass("active")){
        setTimeout(function(){
          _this.removeClass("active");
          feature_content.removeClass("active");
          feature_content.find(".tab-pane").removeClass("active");
        })
      }
    })
  }

  if($(".feature-tab.feature-tab-toggle").length){
    $(".feature-tab.feature-tab-toggle").on("click", function(){
      var elm = $(this);
      var elm_parent = elm.parent(".feature-item");
      if(!elm.hasClass("active")){
        $(".feature-tab.feature-tab-toggle").removeClass("active");
        $(".feature-item").removeClass("active");
      }
      if(elm.hasClass("active")){
        elm.removeClass("active");
        elm_parent.removeClass("active");
      }else{
        elm.addClass("active");
        elm_parent.addClass("active");
      }
    })
  }


  if(mobile_toggle.length){
    header_overlay.click(function(elm){
      if($(elm.target).hasClass("header-container-overlay")){
        toggleMenu();
      }
    });
    mobile_toggle.click(toggleMenu);
  }

  if($(".slider-service-for").length){
    $('.slider-service-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.slider-service-nav'
    });
    $('.slider-service-nav').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.slider-service-for',
      dots: true,
      centerMode: true,
      focusOnSelect: true
    });
  }

  if($(".service-item-slider").length){

    $('.service-thumb-slider').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.service-item-slider',
      dots: false,
      arrows: false,
      focusOnSelect: true,
      infinite: false
    });

    $('.service-item-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      asNavFor: '.service-thumb-slider',
      infinite: false
    });

    var current = 0; // current slider dupa refresh
    $('.service-thumb-slider .slick-slide:not(.slick-cloned)').eq(current).addClass('slick-current');
    $('.service-item-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
      current = currentSlide;
      $('.service-thumb-slider .slick-slide').removeClass('slick-current');
      $('.service-thumb-slider .slick-slide:not(.slick-cloned)').eq(current).addClass('slick-current');
    });
  }

  if($(".menu-child-level-2-wrapper").length){
    var nav = $(".menu-child-level-2-wrapper");
    var header_height = $(".header-container .menu-child-level-2-wrapper").offset().top;
    var scrollTop = win.scrollTop();
    if(scrollTop > header_height){
      if(!nav.hasClass("fixed")){
        // nav.addClass("fixed-in");
        // setTimeout(function(){
          // nav.removeClass("fixed-in");
          nav.addClass("fixed");
        // },100)
      }
    }else{
      nav.removeClass("fixed");
    };
    win.on("scroll", function(){
      scrollTop = win.scrollTop();
      if(scrollTop > header_height){
        if(!nav.hasClass("fixed")){
          // nav.addClass("fixed-in");
          // setTimeout(function(){
            // nav.removeClass("fixed-in");
            nav.addClass("fixed");
          // },100)
        }
      }else{
        nav.removeClass("fixed");
      };
    })
  }


  var sidebar_spy = $(".sidebar-spy");
  var sidebar_spy_wrap = $(".data-sidebar-spy");
  var sidebar_spy_item = $(".sidebar-spy .item-menu");
  var sidebar_spy_item_link = $(".sidebar-spy .item-link");
  var content_spy = $(".data-content-spy");
  var body = $("html, body");
  if($('.list-menu-item').length) {

    var banner = $('.banner-container-cover')[0];
    var firstItem = $(this).find('.item-menu')[0];
    var allowChange = true;
    if(win.width() >= 992){
      allowChange = true;
    }else{
      allowChange = false;
    }
    function fixFirstItem(){
      scrollTop = win.scrollTop();
      if(scrollTop <= 400 && allowChange){
        if( firstItem !== null && !$(firstItem).hasClass("active")){
          $(firstItem).addClass("active");
        }
      }
    }
    function fixTabMobile(){
      var section_item_tab_wrap = $(".section-item .nav-tab-header");
      var section_item_tab = $(".section-item .nav-tab-header .nav-tab-item");
      var section_item_content_wrap = $(".section-item .tab-content");
      var section_item_content = $(".section-item .tab-content .tab-pane");
      if(win.width() > 767){
        $.each(section_item_tab_wrap, function(){
          var _this = this;
          var firstItem = $(_this).find(".nav-tab-item")[0];
          $(firstItem).addClass("active");
        })

        $.each(section_item_content_wrap, function(){
          var _this = this;
          var firstItem = $(_this).find(".tab-pane")[0];
          $(firstItem).addClass("in active");
        })
      }else{
        if(section_item_tab.length){
          $(section_item_tab).removeClass("active")
        }
        if(section_item_content.length){
          $(section_item_content).removeClass("in active")
        }
      }
    }
    fixTabMobile()
    win.on("scroll", function(){
      fixFirstItem();
    })
    win.on("resize", function(){
      if(win.width() == winWidth){
        return false;
      }
      if(win.width() >= 992){
        allowChange = true;
      }else{
        allowChange = false;
      }
      var id = content_spy.find(".section-item.active").attr("id");
      setTimeout(function(){
        scrollTo("#"+id);
      })
    })
  }

  $(".menu-item-child-level-2.has-sub-large>a").click(function(){
    if(isTablet){
      return false;
    }
  })
  function setButtonHtml(href){
    $.each(sidebar_spy_item, function(){
      var _this = $(this);
      if(_this.find(".item-link").attr("href") == href){
        $(".btn-drop-tab-res .txt").html(_this.find(".item-link").html());
      }
    })
  }
  function getHeaderContainer(){
    var headerContainer = 0;
    if(win.width()>=768){
      headerContainer = $(".menu-child-level-2-wrapper").height();
    }else{
      headerContainer = $(".navbar-header.navbar-header-mobile").height();
    }
    return headerContainer;
  }
  function scrollTo(href, scrollTo){
    if(typeof scrollTo == "undefined"){
      scrollTo = true;
    }
    var header_content = $(".menu-top-auto-scroll");
    var header_content_height = 0;
    if(header_content.length){
      header_content_height = 70;
    }
    if($(".faq-container .panel-heading").length){
      header_content_height = getHeaderContainer();
      if(win.width()>=768){
        if(win.scrollTop() > $(".header-container").height()){
        }else{
          header_content_height += 46;
        };
      }
      header_content_height +=$(".panel-title-link[href='"+href+"']").innerHeight() + 10;
    }
    content_spy.find(".section-item").removeClass("active");
    try{
      if(href && $(href).length){
        if(scrollTo){
          body.stop().animate({scrollTop:$(href).offset().top-header_content_height}, '500', 'swing');
        }
        $(href).addClass("active");
        setButtonHtml(href);
      }
    }catch(e){
      console.log(e.message)
    }
  }
  if(window.location.hash){
    if(win.width()<768 && $(".menu-auto-hold-noscroll").length){
    }else{
      scrollTo(window.location.hash);
    }

    if(sidebar_spy_item.length){
      sidebar_spy_item.removeClass("active");
      $.each(sidebar_spy_item, function(){
        var _this = $(this);
        if(_this.find(".item-link").attr("href") == window.location.hash){
          _this.addClass("active")
        }
      })
    }

    if($(".faq-container .panel-heading").length){
      $.each($(".faq-container .panel-heading"), function(){
        var _this = $(this);
        var panelLink = _this.find(".panel-title-link");
        if(panelLink.attr("href") == window.location.hash){
          panelLink.trigger("click");
          scrollTo(window.location.hash)
        }
      })
    }
  }else{
    setTimeout(function(){
      if(win.width()>991){
        if($(".menu-auto-hold-scroll .sidebar-spy .item-link").length){
          $($(".menu-auto-hold-scroll .sidebar-spy .item-link")[0]).trigger("click");
        }
      }else if(win.width()>=768){
        if($(".menu-auto-hold-noscroll .sidebar-spy .item-link").length){
          $($(".menu-auto-hold-noscroll .sidebar-spy .item-link")[0]).trigger("click");
        }
      }
    },500)
  }
  if(sidebar_spy_item_link.length){
    sidebar_spy_item_link.on("click", function(e){
      var href = $(this).attr("href");
      if(win.width()>991){
        scrollTo(href);
      }else{
        scrollTo(href, false);
      }
      $(".dropdown").removeClass("open");
      if(win.width()<=991){
        sidebar_spy_item.removeClass("active");
        $(this).parent().addClass("active");
        return false;
      }
    })
  }
  if(sidebar_spy.length && content_spy.length){
    function fixMenuSpy(){
      var sidebar_spy_offset = sidebar_spy.offset().top;
      var sidebar_spy_height = sidebar_spy.height();
      var content_spy_height = content_spy.height();
      var content_spy_offset = content_spy.offset().top;
      if(content_spy_height < sidebar_spy_height){
        sidebar_spy.css({
          "position": "static",
          "top": 0
        })
        return false;
      }
      if(win.scrollTop() + getHeaderContainer() < content_spy_height + content_spy_offset - sidebar_spy_height){
        if(win.scrollTop() + getHeaderContainer() < content_spy_offset){
          sidebar_spy.css({
            "position": "static",
            "top": 0
          })
        }else{
          sidebar_spy.css({
            "position": "fixed",
            "top": 120
          })
        }
      }else{
        sidebar_spy.css({
          "position": "absolute",
          "top": content_spy_height - sidebar_spy_height
        })
      }
    }

    fixMenuSpy();
    win.on("scroll", function(){
      fixMenuSpy();
    })

    sidebar_spy.width(sidebar_spy.parent().width());
    win.on("resize", function(){
      if(win.width() == winWidth){
        return false;
      }
      sidebar_spy.width(sidebar_spy.parent().width());
    })
  }

  var guide_nav_item = $(".nav-tab-header .nav-tab-item");
  if(guide_nav_item.length){
    function fixNavGuide(){
      guide_nav_item.css("max-width", "none");
      setTimeout(function(){
        $.each(guide_nav_item,function(){
          var _this = $(this);
          var _this_content = _this.find(".nav-tab-content");
          var _this_content_pl = parseInt(_this_content.css('padding-left').replace("px", ""));
          var _this_content_pr = parseInt(_this_content.css('padding-right').replace("px", ""));
          _this.css("max-width", _this_content.width()+_this_content_pl+_this_content_pr+20);
        })
      })
    }
    fixNavGuide();
    win.on("resize", function(){
      if(win.width() == winWidth){
        return false;
      }
      fixNavGuide();
    })
  }
  function bootstrapScrollSpy(){
    if(win.width()>=992){
      $('body').scrollspy({
        target: '.menu-auto-hold-scroll'
      })
    }else{
    }
  }
  function fixFooterBottom(){
    $(".wrapper").css("margin-bottom", $(".footer-container").innerHeight())
  }


  if($(".pane-content-header").length){
    $(".pane-content-header").on("click", function(){
      var _this = $(this);
      if(_this.parent().hasClass("active")){
        _this.parent().removeClass("active")
      }else{
        _this.parent().addClass("active")
      }
      $(".page-guide-main>.container>.row").height($(".data-content-spy").height());
    })
  }

  if($(".menu-auto-hold-noscroll .item-link").length && isMobile){
    $(".menu-auto-hold-noscroll .item-link").on("click", function(){
      fixNavGuide();
      $(".page-guide-main>.container>.row").height($(".data-content-spy").height());
      if(!$(".data-sidebar-spy").hasClass("guide-current")){
        $(".data-sidebar-spy").addClass("guide-current");
      }
      if(!$(".data-content-spy").hasClass("guide-current")){
        $(".data-content-spy").addClass("guide-current");
      }
    })
    if($(".data-content-spy .title-heading").length){
      $(".data-content-spy .title-heading").on("click", function(){
        if($(".data-sidebar-spy").hasClass("guide-current")){
          $(".page-guide-main>.container>.row").height($(".data-sidebar-spy").height());
          $(".data-sidebar-spy").removeClass("guide-current")
        }
        if($(".data-content-spy").hasClass("guide-current")){
          $(".data-content-spy").removeClass("guide-current")
        }
      })
    }
    if(window.location.hash){
      setTimeout(function(){
        $.each($(".menu-auto-hold-noscroll .item-link"), function(){
          var _this = $(this);
          if(_this.attr("href") == window.location.hash){
            _this.trigger("click");
          }else{
            var item_link_parent = $("a[href='"+window.location.hash+"']").parents(".list-submenu-item");
            if(item_link_parent.length){
              var href = item_link_parent.prev().attr("href");
              $("a[href='"+href+"']").trigger("click");
            }
          }
        })
      },500)
    }else{
      $(".page-guide-main>.container>.row").height($(".data-sidebar-spy").height());
    }
  }

  if($("select.form-control").length){
    $("select.form-control").on("change", function(){
      var _this = $(this);
      if(_this.val()){
        _this.addClass("has-value");
      }else{
        _this.removeClass("has-value");
      }
    })
  }
  var lastId,
      topMenu = $(".menu-top-auto-scroll"),
      topMenuHeight = topMenu.height(),
      menuItems = topMenu.find("a"),
      scrollItems = menuItems.map(function() {
        var item = $($(this).attr("href"));
        if (item.length) {
          return item;
        }
      });
  function getTopMenuHeight(){
    var topMenuHeight = 0;
    if(win.width() <= 991){
      topMenuHeight = 54;
      if(win.width()>=768){
        if(scrollTop > header_height){
        }else{
          topMenuHeight += 46
        };
      }
    }else{
      topMenuHeight = 72;
    }
    return topMenuHeight;
  }
  if(menuItems.length){
    menuItems.click(function(e) {
      topMenuHeight = getTopMenuHeight();
      var _this = $(this);
      var headerContainer = getHeaderContainer();
      var href = $(this).attr("href");
      var offsetTop;
      var nav = $(".menu-child-level-2-wrapper");
      var header_height = $(".header-container").height();
      var scrollTop = win.scrollTop();


      offsetTop = href === "#" ? 0 : $(href).offset().top + 110 - headerContainer - topMenuHeight;
      console.log($(href).offset().top + 120 - headerContainer - topMenuHeight)
      $('html, body').stop().animate({
        scrollTop: offsetTop
      }, 500, "swing", function(){
        topMenu.find(".items").removeClass("active");
        _this.parent().addClass("active");
      });
      e.preventDefault();
    });
  }


  if($('body').find('.wrap-menu').length > 0) {
    MenuTop = $(".wrap-menu").offset().top;
  }
  TopHeader = $('.menu-child-level-2-header').height();
  ScrollTop = $(window).scrollTop() + TopHeader;

  if(MenuTop <= ScrollTop) {
    $(".wrap-menu").addClass('active');
    $('.menu-child-level-2-wrapper').addClass('border-bottom');
  } else {
    $(".wrap-menu").removeClass('active');
    $('.menu-child-level-2-wrapper').removeClass('border-bottom');
  }

  win.scroll(function() {
    topMenuHeight = getTopMenuHeight();
    var fromTop = $(this).scrollTop() + topMenuHeight;

    var ScrollTop = $(this).scrollTop() + TopHeader;

    if(MenuTop <= ScrollTop) {
    $(".wrap-menu").addClass('active');
    $('.menu-child-level-2-wrapper').addClass('border-bottom');
    } else {
    $(".wrap-menu").removeClass('active');
    $('.menu-child-level-2-wrapper').removeClass('border-bottom');
    }

    var cur = scrollItems.map(function() {
      if ($(this).offset().top < fromTop+40)
        return this;
    });
    cur = cur[cur.length - 1];
    var id = cur && cur.length ? cur[0].id : "";

    if(menuItems.length){
      if(win.scrollTop() + win.height() > $(document).height() - 10) {
        menuItems.parent().removeClass("active");
        $(menuItems[menuItems.length-1]).parent().addClass("active");
        lastId = scrollItems[0].id
      }else{
        if (lastId !== id) {
          lastId = id;
          menuItems
            .parent().removeClass("active")
            .end().filter("[href='#" + id + "']").parent().addClass("active");
        }
      }
    }
  });

  function resizeOwlnav(){
    if($(".carousel-container .owl-nav")){
      $(".carousel-container .owl-nav").css("opacity","0");
      setTimeout(function(){
        $(".carousel-container .owl-nav").height($(".carousel-container .img-responsive").height())
        $(".carousel-container .owl-nav").css("opacity","1");
      },500)
    }
  }

  resizeOwlnav();
  bootstrapScrollSpy();
  fixFooterBottom();
  win.on("resize", function(){
    if(win.width() == winWidth){
      return false;
    }else{
      winWidth = win.width();
    }
    bootstrapScrollSpy();
    fixFooterBottom();
    resizeOwlnav();
    $(".nano").nanoScroller();
  });

  $('.gallery-slideshow').lightGallery({
    mode: 'lg-fade',
    cssEasing: 'ease',
    speed: 1000,
    getCaptionFromTitleOrAlt: false
  });

  if ($(window).width() <= 767) {
    $('#asb .icon-info').on('click', function(e) {
      $(this).parent().find('.box-popup-option').css({
        'visibility': 'visible'
      })
    });
    $('.btn-close-tooltip').on('click', function(e) {
      $(this).parent().css({
        'visibility': 'hidden'
      });
    });
  }

  var tableFix = $('.box-table-if');
  if (tableFix.length) {
    var theadWidth = $('.box-table-if').width();
    var theadTop = $('.box-table-if thead').offset().top;
    var theadHeight = $('.box-table-if thead').height();

    $(window).resize(function() {
      setTimeout(function() {
        theadWidth = $('.box-table-if').width();
        fixHeadTable(theadTop, theadWidth, theadHeight)
      })
    })

    $(window).scroll(function() {
      fixHeadTable(theadTop, theadWidth, theadHeight)
    });
  }
});


function fixHeadTable(theadTop, theadWidth, theadHeight) {
  var navbarHeight;

  if ($(window).width() > 767) {
    navbarHeight = $('.menu-child-level-2-wrapper').height();
  } else {
    navbarHeight = $('.navbar-header-mobile').height();
  }
  if ($(window).scrollTop() + $('.box-table-if .row-tb-thead-if').height() < $('.box-table-if').position().top + $('.box-table-if').height()) {
    if ($(window).scrollTop() >= (theadTop - navbarHeight)) {
      $('.box-table-if thead').width(theadWidth);
      $('.box-table-if thead').css({
        'position': 'fixed',
        'top': navbarHeight
      })
      $('.box-table-if').css({
        'padding-top': theadHeight
      })
    } else {
      $('.box-table-if thead').width('auto');
      $('.box-table-if thead').css({
        'position': 'static'
      })
      $('.box-table-if').css({
        'padding-top': 0
      })
    }
  } else {
    $('.box-table-if thead').width('auto');
    $('.box-table-if thead').css({
      'position': 'static'
    })
    $('.box-table-if').css({
      'padding-top': 0
    })
  }
}

$(window).resize(function(){
  TopHeader = $('.menu-child-level-2-header').height();
  var lastId,
      topMenu = $(".menu-top-auto-scroll"),
      topMenuHeight = 82,
      menuItems = topMenu.find("a"),
      scrollItems = menuItems.map(function() {
        var item = $($(this).attr("href"));
        if (item.length) {
          return item;
        }
      });
    menuItems.click(function(e) {
      var href = $(this).attr("href"),
        offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
      $('html, body').stop().animate({
        scrollTop: offsetTop
      }, 500);
      e.preventDefault();
    });

  var fromTop = $(this).scrollTop() + topMenuHeight;

  var ScrollTop = $(this).scrollTop() + TopHeader;

  if(MenuTop <= ScrollTop) {
    $(".wrap-menu").addClass('active');
    $('.menu-child-level-2-wrapper').addClass('border-bottom');
  } else {
    $(".wrap-menu").removeClass('active');
    $('.menu-child-level-2-wrapper').removeClass('border-bottom');
  }

  var cur = scrollItems.map(function() {
  if ($(this).offset().top < fromTop)
    return this;
  });
  cur = cur[cur.length - 1];
  var id = cur && cur.length ? cur[0].id : "";

  if (lastId !== id) {
  lastId = id;
  menuItems
    .parent().removeClass("active")
    .end().filter("[href='#" + id + "']").parent().addClass("active");
  }
});
// if(screen.width >= 768) {
//   $('.option-hover').hover(function(e){
//     e.preventDefault();
//     $('.toolstip-option').toggle();
//   })
// }
$('body').click(function (e) {
    var resultsSearchSuggestion = $("ul.results");
    var inputAirlineSearch = document.getElementsByClassName('ip-search-if')[0];
    if ($(e.target).parents('ul.results').length <= 0 && !$(e.target).hasClass("ip-search-if")) {
        $(resultsSearchSuggestion[0]).addClass("hide-item");
    } else {
        $(resultsSearchSuggestion[0]).removeClass("hide-item");
    }
});